import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const Franchise = ({ t }) => {
  return (
    <>
      <div className="jobs_wrapper align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={t('Become_QFX_Franchise')}
          />
          <CenteredTitle
            firstHalf={t('Interested_in_QFX_Franchise')}
            secondHalf=""
          />
        </div>
        <section className="row">
          <article className="col-xl-12 mx-auto event_back color-black-1">
            <p className="static_page_highlight mb-4 mb-xl-5 ">
              {t('As_an_industry')}
            </p>
            <p>{t('For_inquiries')} :</p>

            <p className="static_page_highlight my-3 my-xl-4">
              {t('Phone')}: <a href="tel:9813876466">9813876466</a>
            </p>

            <p className="static_page_highlight my-3 my-xl-4">
              {t('Email')}:{' '}
              <a href="mailto:marketing@teamquest.com.np">
                marketing@teamquest.com.np
              </a>
            </p>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Franchise);
