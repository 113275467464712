import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import { useDispatch } from 'react-redux';

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const [register, setRegister] = useState(false);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
          <article className="col-lg-10 col-xl-12 mx-auto">
            <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} />
            <CenteredTitle firstHalf={t('About Us')} secondHalf={''} />
            <div className="about_us_align">
              <p className="about_us_hero_text_synopsis text-dark">
                {t('AboutUs.headerContent')}
              </p>
              <p className="about_us_hero_text_movie_details">
                {t('AboutUs.loginMessage')}
              </p>
              <Button
                text={t(`header-footer-menu.Sign in`)}
                classes=""
                size="lg"
                onClick={() => handleLoginPopup(true)}
              />
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
