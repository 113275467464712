import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import { Accordion, Card } from 'react-bootstrap';
import CenteredTitle from '@components/partials/CenteredTitle';
import { useSelector } from 'react-redux';
import BreadCrumb from '@components/partials/BreadCrumbs';
import searchIcon from '@assets/pictures/svgs/search.svg';
import close from '@assets/pictures/svgs/close.svg';
import InlineScroll from '@components/partials/InlineScroll';
import useIsMobile from '../customHooks/useIsMobile';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomAccordion from '@components/Accordian';

const FAQNewContainer = ({ t }) => {
  const selected_language = useSelector((state) => state.movies.selected_language);
  const isMobile = useIsMobile();
  const lengthOfFaqsArray = 10;
  const [activeAccordionTab, setActiveAccordionTab] = useState(
    [...new Array(lengthOfFaqsArray)].map((item, index) => (index ? false : true)),
  );
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState('0');
  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = Array(lengthOfFaqsArray).fill(false);
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  let [faqs, setFaqs] = useState([]);
  let [filteredFaq, setFilteredFaq] = useState([]);
  let [categories, setCategories] = useState([]);

  useEffect(() => {
    let faqs = [];
    let categories = new Set();

    for (let i = 1; i < 36; i++) {
      let faq = {
        q: t(`FAQ.QN${i}`),
        a: t(`FAQ.AN${i}`),
        category: 'General',
        categoryDisplay: t('General'),
      };

      if (i >= 1 && i <= 2) {
        faq.category = 'MOVIE SCREENING & ONLINE PURCHASE';
        faq.categoryDisplay = t('MOVIE SCREENING & ONLINE PURCHASE');
      } else if (i >= 3 && i <= 12) {
        faq.category = 'MOVIE RESERVATION AND PURCHASE';
        faq.categoryDisplay = t('MOVIE_RESERVATION_AND_PURCHASE');
      } else if (i >= 13 && i <= 15) {
        faq.category = 'ONLINE REGISTRATION';
        faq.categoryDisplay = t('ONLINE_REGISTRATION');
      } else if (i >= 16 && i <= 18) {
        faq.category = 'CONTACT US';
        faq.categoryDisplay = t('CONTACT_US');
      } else if (i >= 19 && i <= 21) {
        faq.category = 'MY LOYALTY';
        faq.categoryDisplay = t('MY_LOYALTY');
      } else if (i >= 22 && i <= 31) {
        faq.category = 'GENERAL TERMS AND CONDITIONS';
        faq.categoryDisplay = t('GENERAL_TERMS_AND_CONDITIONS');
      } else if (i >= 32 && i <= 35) {
        faq.category = 'QFX MOBILE APPLICATION';
        faq.categoryDisplay = t('QFX_MOBILE_APPLICATION');
      }

      faqs.push(faq);
      categories.add(
        JSON.stringify({
          category: faq.category,
          categoryDisplay: faq.categoryDisplay,
        }),
      );
    }

    setFaqs(faqs);
    setFilteredFaq(faqs);
    setCategories(Array.from(categories.map((c) => JSON.parse(c))));
  }, [selected_language]);

  let [search, setSearched] = useState('');
  let [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    let filteredBySearch = [...faqs];

    if (!!selectedCategory) {
      filteredBySearch = filteredBySearch.filter((f) => {
        return f.category == selectedCategory;
      });
    }

    if (!!search) {
      filteredBySearch = filteredBySearch.filter((f) => {
        if (f.q.toLowerCase().includes(search.toLowerCase())) return true;
        else if (f.a.toLowerCase().includes(search.toLowerCase())) return true;

        return false;
      });
    }

    setFilteredFaq(filteredBySearch);
  }, [faqs, search, selectedCategory]);

  const [key, setKey] = useState('Movie_Screening');
  const [icon, seticon] = useState(false);

  const setIconFun = () => {
    seticon(!icon);
  };
  // let active_minus_svg = $('#answerdiv').hasClass('show');

  // console.log(active_minus_svg, 'active_minus_svg');

  // const el = document.querySelector('.show');

  // console.log(el, 'ellllllll');
  let mywebsite = 'www.qfxcinemas.com';

  let Movie_ScreeningData = [
    // {
    //   key: 1,
    //   title: `What movies are currently being screened at QFX Cinemas ?`,
    //   content: `Visit the “Now Showing” section in our Mobile Application or website at <a href='www.qfxcinemas.com'>www.qfxcinemas.com.</a>`,
    // },
    // {
    //   key: 2,
    //   title: 'What movies will be releasing soon to QFX Cinemas ?',
    //   content: `Click on the “Coming Soon” section for line ups of movies releases. All these information is available in our Mobile Application or website at <a href='www.qfxcinemas.com'>www.qfxcinemas.com.</a>`,
    // },
    // {
    //   key: 3,
    //   title: 'Can I purchase movie tickets and food online ?',
    //   content: `Sure! Just download the QFX Cinemas App or visit our website <a href='www.qfxcinemas.com'>www.qfxcinemas.com.</a> You can also buy QFX movie tickets using Khalti App.`,
    // },
    // {
    //   key: 4,
    //   title:
    //     'Can I reserve movie tickets online or book tickets through phone booking ?',
    //   content: `We have currently disabled movie reservations and we do not take phone bookings anymore. You can buy any movie tickets using our mobile App or through our website at <a href='www.qfxcinemas.com'>www.qfxcinemas.com.</a>`,
    // },
    {
      key: 5,
      title: 'What are the steps to buy tickets and food online?',
      content: `<p> <b>Step 1.</b> Download and open QFX Cinemas mobile App or go to <a href='www.qfxcinemas.com'>www.qfxcinemas.com.</a></p>
        <p> <b>Step 2.</b> Click on the city and theatre of your choice</p>
        <p> <b>Step 3.</b> Click on the movie poster and select the date when you would like to watch movie. Click on your preferred movie time and select the number of seats for ticket purchase</p>
        <p> <b>Step 4.</b> Hit “Confirm”, verify the movie details, verify your email and phone number and click “Confirm” again.</p>
        <p> <b>Step 5.</b> Select the payment method and you will be redirected to a separate page where you can enter your payment details. You can also input voucher or promo code if you have any available and click “Apply” before making payment for the remaining amount</p>

        <p>You will see a confirmation page with QR code and also receive movie ticket confirmation in email listed. You can scan this QR to enter the theatre premises upto half an hour before the movie show time.</p>
        `,
    },
    {
      key: 6,
      title: 'What methods of payment are accepted online ?',
      content:
        'You can purchase QFX Cinemas movie tickets online if you have an e-banking service with Khalti, Esewa, IME pay, Connect IPS, Nepal Investment Bank or any VISA or MasterCard (debit or credit).You can also use your loyalty rewards points for purchases if you are a registered customer. You can find the details of Loyalty in “Club QFX” tab of App or website.',
    },
    {
      key: 7,
      title: 'What is the benefit of online ticket and food purchase ?',
      content:
        'Online ticketing and food purchase ensures that you do not need to visit the Box Office and queue up. You can simply present your ticket QR Code from the QFX App or website in order to enter our premises before your showtime. You can also earn loyalty points with every Rupee you spend. With online purchase of food, we can also make your order ready for pick up without you standing in queue. You can also get online access to offers and promotions available at QFX cinema theatres.',
    },
    {
      key: 8,
      title: 'How do I know if my purchase is confirmed ?',
      content:
        'Confirmation of your purchase is clearly displayed at the end of the buying process. Your e- tickets will come inside your “My Tickets” page under “My Profile”. You will also receive an email with all purchase details. Simply scan your e-tickets before you enter our theater',
    },
    {
      key: 9,
      title: 'How do I know if my reservation is confirmed ?',
      content:
        'We do not take any seat reservations. You can directly buy movie tickets through our QFX App or website.',
    },
    {
      key: 10,
      title: 'How do I do Group Reservations/Hall Reservation ?',
      content: `You can email <a href='mailto:support@qfxcinemas.com'>support@qfxcinemas.com</a> with details of your request and your contact information. Our marketing team will reply within 2-3 business days.`,
    },
    {
      key: 11,
      title:
        'What are the benefits of registering on www.qfxcinemas.com or QFX Cinemas App?',
      content:
        'Registration with QFX Cinemas ensures you get access to the convenience of seamless online ticket purchases. QFX Cinemas also offers rewards on tickets and food to its customers through the Club QFX loyalty program. You can find details of our new loyalty program under the “Club QFX” tab in our App and website.',
    },
    {
      key: 12,
      title: 'How do I change my profile information ?',
      content:
        'If you would like to update your personal profile information, you can go to “My Profile” > Make your Changes > Click “Confirm”',
    },
    {
      key: 13,
      title: 'If I forgot my password, how do I reset my online password ?',
      content: `Just click the “Forgot Password” link in the main page. You will receive an OTP confirmation in your email. Enter the OTP number and you can change your password. Please do not share your OTP with anyone. If you have any problems, contact us at <a href='mailto:support@qfxcinemas.com'>support@qfxcinemas.com</a>`,
    },
    {
      key: 14,
      title: 'I want to discontinue my account. How do I do that?',
      content: `If you wish to be removed from QFX membership and loyalty program list, please send your request to <a href='mailto:support@qfxcinemas.com'>support@qfxcinemas.com</a>`,
    },
  ];

  let customer_service_data = [
    {
      key: 1,
      title: `How can I contact QFX Cinemas ?`,
      content: `You can fill our Support Form under tab “Customer Service” available in QFX App or Website for any issues`,
    },
    {
      key: 2,
      title: `What are the Customer Service timings ?`,
      content: `Our working hours are from 10:00 am until 06:00 pm. However, during morning shows, our customer care is available one hour prior to the movie screening.`,
    },
    {
      key: 3,
      title: `What do I do if the customer care phone keeps ringing and not picked up ?`,
      content: `At times when there is a lot of demand for the movies, we get an overwhelming number of calls due to which the incoming calls queue up. We request that you fill out the Customer Service support form to register your issues and our customer service team will get back to you within 2-3 business days.`,
    },
    {
      key: 4,
      title: `Whom do I contact to share grievances, feedback and complaints ?`,
      content: `Please fill our Support Form under tab “Customer Service” available in QFX App or Website`,
    },
    {
      key: 5,
      title: `I am experiencing a problem with my QFX Cinemas mobile application, what should I do ?`,
      content: `Please fill our Support Form available under “Customer Service” tab in QFX website to register your issue.`,
    },
  ];

  let qfx_loyalty_data = [
    {
      key: 1,
      title: `Where can I view my transaction history ?`,
      content: `In order to view your transaction history, click on the “My Tickets” icon under “My Profile”. This page will show all your transactions with QFX Cinemas. For history of your loyalty points, you can visit “Club QFX” loyalty page.`,
    },
    {
      key: 2,
      title: `How do I get rewarded for my loyalty ?`,
      content: `You can use your accumulated loyalty points to get discounts on tickets or food items. QFX Cinemas also has attractive offers for customers as you reach higher loyalty tiers. Details of the loyalty program can be found at “Club QFX” tab in App or website.`,
    },
    {
      key: 3,
      title: `What happens to my Goji Loyalty Points ?`,
      content: `The Goji Coins (plus converted QFX points to Goji Coins) will be transferred as Club QFX Loyalty points. Customers will be eligible to get Club QFX Loyalty points only if their QFX account was synced to their Goji account.`,
    },
    {
      key: 4,
      title: `How does Club QFX Loyalty work at QFX Cinemas ?`,
      content: `Details of the loyalty program can be found at “Club QFX” tab in App or website.`,
    },
  ];

  let general_data = [
    {
      key: 1,
      title: `Do I have to pay for my child who will sit on my lap ?`,
      content: `Infants under 1 year (or visibly lap child) will strictly not be allowed in the theater. This is also due to health issues they might encounter.Everyone (all individuals) requires their own separate ticket.`,
    },
    {
      key: 2,
      title: `Are there extra charges for buying movie tickets online ?`,
      content: `There is no extra charge for buying your movie tickets online.`,
    },
    {
      key: 3,
      title: `Can we carry outside food into the theater ?`,
      content: `Outside food/drinks are not allowed inside the theater. Chewing gum, guthka, cigarettes, etc. are also strictly prohibited.`,
    },
    {
      key: 4,
      title: `Am I allowed to watch movie if I am intoxicated ?`,
      content: `Customer under influence of alcohol or visibly intoxicated might not get admission or may be requested to leave the QFX premises. Anyone who is loud, boisterous, disorderly, argumentative, annoying other patrons and staff, physically violent, aggressive, using offensive language, exhibiting inappropriate behavior and vomiting will be not be allowed permission inside QFX theaters or will be asked to leave immediately.`,
    },
    {
      key: 5,
      title: `What are the parking facilities at QFX Cinemas ? Do I have to pay ?`,
      content: `All QFX Cinemas are located in buildings with parking facilities. Parking charges may vary according to the individual cinema locations.`,
    },
    {
      key: 6,
      title: `Can I cancel or reschedule the tickets that I have already bought ?`,
      content: `No, once the ticket is bought, it cannot be refunded or rescheduled`,
    },
    {
      key: 7,
      title: `Where can I get more information on currently showing and upcoming movies at QFX Cinemas ?`,
      content: `If you want to see the runtime, synopsis and trailer of the currently running/ next change movies, click on the picture of the movie. It will take you to the movie page where you can view the information regarding that movie.`,
    },
  ];
  return (
    <>
      <div className="container-fluid faq_new align_new ">
        <section className="row">
          <div className={isMobile ? 'left_right_align' : ''}>
            <BreadCrumb firstHalf={`${t('More')} >`} secondHalf=" FAQ’s" />
            <p className="faq_title text-primary">Frequently Asked Questions</p>
            {/*<CenteredTitle firstHalf={t("Frequently Asked Questions")} secondHalf=""/>*/}
          </div>
          <article className="col-lg-10 col-xl-8 mx-auto color-black faq_inlin_btn_wrapper" style={{color:'white'}}>
            {/* old design start */}
            {/* <label className="fnb-search mb-4" style={{ width: '80%' }}>
              <img src={searchIcon} alt="search" onClick={(e) => {}} />
              <input
                type="text"
                placeholder={t('Search for concessions')}
                value={search}
                onChange={(e) => {
                  setSearched(e?.target?.value || '');
                }}
                style={{ borderRadius: '38px' }}
              />
              <img
                src={close}
                alt="close"
                className={`${!!search ? 'show' : ''}`}
                onClick={() => {
                  setSearched('');
                  setSelectedCategory('');
                }}
              />
            </label>
            <div>
              <InlineScroll>
                {categories.map((c, i) => (
                  <button
                    key={i}
                    className={`faq_btn${
                      c.category === selectedCategory ? '' : '_inactive'
                    }`}
                    onClick={() => {
                      if (c.category === selectedCategory) setSelectedCategory('');
                      else setSelectedCategory(c.category);
                    }}
                  >
                    {c.categoryDisplay}
                  </button>
                ))}
              </InlineScroll>
            </div> */}

            {false && (
              <Accordion
                activeKey={activeAccordionTabIndex}
                defaultActiveKey={activeAccordionTabIndex}
                className="faq_new_accordion"
              >
                {filteredFaq.map((faq, index) => (
                  <Card key={index}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={`${index}`}
                      className={`${activeAccordionTab[index] ? 'active' : ''}`}
                      onClick={() => handleAccordionTab(index)}
                    >
                      <p>
                        {faq.q} {/*({faq.categoryDisplay})*/}
                      </p>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <p>{faq.a}</p>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            )}
            {/* old design end */}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab
                eventKey="Movie_Screening"
                title="Movie Screening and Online Purchase"
              >
                <div className="accordion">
                  {Movie_ScreeningData?.map(({ title, content }) => (
                    <CustomAccordion title={title} content={content} />
                  ))}
                </div>
              </Tab>
              <Tab eventKey="Customer_Service" title="Customer Service at QFX">
                <div className="accordion">
                  {customer_service_data?.map(({ title, content }) => (
                    <CustomAccordion title={title} content={content} />
                  ))}
                </div>
              </Tab>
              <Tab
                eventKey="Club_QFX_Loyalty_Program"
                title="Club QFX Loyalty Program"
              >
                <div className="accordion">
                  {qfx_loyalty_data?.map(({ title, content }) => (
                    <CustomAccordion title={title} content={content} />
                  ))}
                </div>
              </Tab>
              <Tab
                eventKey="General_Terms_and_Conditions"
                title="General Terms and Conditions"
              >
                <div className="accordion">
                  {general_data?.map(({ title, content }) => (
                    <CustomAccordion title={title} content={content} />
                  ))}
                </div>
              </Tab>
            </Tabs>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(FAQNewContainer);
