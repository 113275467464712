import React, { useEffect, useLayoutEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  resetScreeningTypes,
  setOndemandShow,
} from '../../../store/movies/movieActions';
import { currencyFormatter } from '../../../helper/currencyFormatter';
import useIsMobile from '../../../customHooks/useIsMobile';
import SeatLayoutPopup from '../../partials/popups/SeatLayoutPopup';
import { fetchAudiSeatLayout } from '../../../store/ancilliary/ancilliaryActions';

import { ReactComponent as Dx } from '@assets/pictures/svgs/4dx.svg';

const BookingShows = (props) => {
  const {
    t,
    classes,
    screen,
    format,
    cinema,
    formatIndex,
    screenIndex,
    cinemaIndex,
    onClickShowMobile,
    isPrivate,
    allLangWithSubs = false,
  } = props;
  // console.log('props:', props)
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  let userSelection = useSelector((state) => state.movies.userSelection);
  const selectedCinema = useSelector((state) => state.movies.selectedCinema);
  let cinema_id = selectedCinema?.cinema_id;

  const [schedules, setSchedules] = useState([]);
  const [schedulesAfterMidnight, setSchedulesAfterMidnight] = useState([]);

  // useEffect(() => {
  //   console.log("screen", typeof (screen), screen)
  //   // if(screen)
  // }, [screen])

  // let sorted_value = console.log(schedules, 'schedules');
  useLayoutEffect(() => {
    let isApiGotSessions = !!screen?.schedule_list?.[0]?.show_timing_label;
    // console.log('isApiGotSessions:', isApiGotSessions)

    let filteredSchedules = [];
    let filteredAfterMidnightSchedules = [];
    let sortedSch = screen?.schedule_list;
    // console.log('sortedSch:', sortedSch)
    let sortedAfterMidnightSch = screen?.schedule_list_after_midnight;

    if (isApiGotSessions) {
      // sortedSch.sort((a, b) => {
      //   // Turn your strings into dates, and then subtract them
      //   // to get a value that is either negative, positive, or zero.
      //   return new Date(a.date_final_output) - new Date(b.date_final_output);
      // })
      sortedSch.sort((a, b) => {
        if (
          String(a.showTime).startsWith('0') &&
          String(b.showTime).startsWith('0') &&
          String(a.show_timing_label).startsWith('Night') &&
          String(b.show_timing_label).startsWith('Night')
        ) {
          return a.date_unix - b.date_unix;
        } else if (
          (String(a.showTime).startsWith('0') ||
            String(b.showTime).startsWith('0')) &&
          (String(a.show_timing_label).startsWith('Night') ||
            String(b.show_timing_label).startsWith('Night'))
        ) {
          return b.date_unix - a.date_unix;
        } else {
          return a.date_unix - b.date_unix;
        }
      });
    }

    if (userSelection?.selectedSession) {
      if (isApiGotSessions) {
        filteredSchedules = sortedSch.filter(
          (s) => s?.show_timing_label === userSelection?.selectedSession?.label,
        );

        filteredSchedules = sortedSch.filter((s) => {
          if (
            [userSelection?.selectedSession?.label].includes('Morning') &&
            s.show_timing_label == 'Night' &&
            s._isPrevDayAmShow
          ) {
            return true;
          }

          if (s.show_timing_label == 'Night') {
            return (
              [userSelection?.selectedSession?.label].includes(
                s.show_timing_label,
              ) && !s._isPrevDayAmShow
            );
          }

          return [userSelection?.selectedSession?.label].includes(
            s.show_timing_label,
          );
        });

        filteredAfterMidnightSchedules = sortedAfterMidnightSch.filter((s) =>
          [userSelection?.selectedSession?.label].includes(s.show_timing_label),
        );
      } else
        sortedSch.forEach((s) => {
          if (
            moment()
              .add(s.showTime)
              .isBetween(
                moment().add(userSelection.selectedSession.from),
                moment().add(userSelection.selectedSession.to),
              )
          ) {
            filteredSchedules.push(s);
          }
        });
    } else {
      filteredSchedules = sortedSch;
      filteredAfterMidnightSchedules = sortedAfterMidnightSch;
    }

    setSchedules(filteredSchedules);
    setSchedulesAfterMidnight(filteredAfterMidnightSchedules || []);
  }, [screen, userSelection.selectedSession]);

  let price = screen?.schedule_list
    .flatMap((sc) => sc?.priceArray.flatMap((p) => p))
    .sort((a, b) => a?.pricing - b?.pricing)?.[0]?.pricing;
  let showPriceIsPrivate =
    screen?.schedule_list
      .flatMap((sc) => sc?.priceArray.flatMap((p) => p))
      .sort((a, b) => a?.pricing - b?.pricing)?.[0]?.isPrivate ?? false;

  if (!isPrivate) {
    price = screen?.schedule_list
      .flatMap((sc) => sc?.priceArray.filter((p) => p?.pricing).flatMap((p) => p))
      .sort((a, b) => a?.pricing - b?.pricing)?.[0]?.pricing;
  }

  // var isTouch
  // if (typeof document !== 'undefined') {
  //   isTouch = 'ontouchstart' in document.documentElement;
  // }
  const [loader, setLoader] = useState(false);
  const [popUpSeatLayout, setPopupSetSeatLayout] = useState(false);
  const onClickAudiCard = (screen_id) => {
    if (screen_id) {
      setLoader(true);
      dispatch(fetchAudiSeatLayout({ screen_id: screen_id, callback: setLoader }))
        .then(() => {
          // setPopupSetSeatLayout(true)
        })
        .finally(() => {
          // setLoader(false)
        });
    }
  };

  if (schedules?.length === 0) return <></>;
  return (
    <>
      <SeatLayoutPopup
        show={popUpSeatLayout}
        closeFunc={setPopupSetSeatLayout}
        loader={loader}
      />
      <div className="booking_shows_wrapper">
        <article
          className={`${isPrivate
              ? ' booking_shows_screen_n_price_golden'
              : ' booking_shows_screen_n_price'
            }`}
        >
          {/*<h5>{screen?.screen_name} - {format?.versionDataFallback || format?.version_name}</h5>*/}
          {
            <h5>
              <span style={{ color: '#fff' }}>{cinema?.cinema_name}</span>
            </h5>
          }
          <div className='text-center' style={{color:'#f9ed1a'}}><h6>({format?.movie_lang_iso_3})</h6></div> 
          <h5>
            {false && (
              <span
                style={{ color: "#1f1c3c" }}
                onClick={() => {
                  // history.push(`/cinema-listing`)
                  // if (!!cinema_id) {
                  //   history.push(`/cinema-details/${cinema_id}`)
                  // }
                  if (screen?.screen_id) {
                    setPopupSetSeatLayout(true);
                    onClickAudiCard(screen?.screen_id);
                  }
                }}
              >
                {screen?.screen_name}
              </span>
            )}
            {/* {(format?.versionDataFallback || format?.movie_lang_iso_3 || format?.version_name) && <h4>
            ({format?.movie_lang_iso_3 || format?.versionDataFallback || format?.version_name})</h4>} */}
            {allLangWithSubs && isPrivate && (
              <h4 className={'isprivate'}>
                (
                {`${!!format?.movie_lang_iso_3 ? format?.movie_lang_iso_3 : ''}${!!format?.sub_1_iso_2 ? `/${format?.sub_1_iso_2}` : ''
                  }${!!format?.sub_1_iso_2 && !!format?.sub_2_iso_2
                    ? `-${format?.sub_2_iso_2}`
                    : ''
                  }`}
                )
              </h4>
            )}
            {/*{!isPrivate && (<h4 className={'!isprivate'}>({
              `${!!format?.movie_lang_iso_3 ? format?.movie_lang_iso_3 : ''}${
                !!format?.sub_lang_1_iso_2 ? `/${format?.sub_lang_1_iso_2}` : ''
              }${
                !!format?.sub_lang_1_iso_2 && !!format?.sub_lang_2_iso_2 ? `-${format?.sub_lang_2_iso_2}` : ''
              }`
            })</h4>)}*/}
          </h5>

          {/* {price > 0 && !showPriceIsPrivate && (
            <h6>
              {t('Starts at')} {curr_code} {currencyFormatter(price)}
            </h6>
          )} */}
        </article>
        <div className='d-block'>
          <article className="booking_shows">
            {schedules
              ?.sort((a, b) => a.showTime.localeCompare(b.showTime))
              .map((show, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`movie-card__tooltip__wrapper ${show.statusType == 'Fully Booked' ? 'inactive' : ''
                      } ${userSelection?.selectedScreen?.screen_id ===
                        screen?.screen_id &&
                        userSelection?.selectedMovieVersion?.movie_details_id ===
                        format?.movie_details_id &&
                        userSelection?.selectedShow?.ss_actual_start_date_time ===
                        show?.ss_actual_start_date_time
                        ? 'active__'
                        : ''
                      }
                       ${show?.mf_name && show?.mf_name.toLowerCase() !== '2d'
                        ? 'with_format'
                        : ''
                      }
                    `}
                    style={{
                      cursor: 'pointer',
                      height: '38px',
                      margin: isMobile ? '5px' : undefined,
                    }}
                    onClick={
                      // isTouch ? undefined :
                      () => {
                        if (show.statusType == 'Fully Booked') {
                          console.log('statusType', show.statusType);
                          return;
                        }

                        dispatch(resetScreeningTypes());
                        dispatch(
                          setOndemandShow({ show, screen, movieVersion: format }),
                        ).then(() => {
                          setTimeout(() => {
                            onClickShowMobile({
                              show,
                              screen,
                              movieVersion: format,
                            });
                          }, 0);
                        });
                        // if (isMobile) {
                        // }
                      }
                    }
                  // onTouchStart={
                  //   !isTouch ? undefined :
                  //   () => {
                  //   if (isMobile) {
                  //     console.log('onTouchStart')
                  //     dispatch(resetScreeningTypes())
                  //     dispatch(setOndemandShow({show, screen, movieVersion: format}))
                  //     setTimeout(() => {
                  //       onClickShowMobile()
                  //     }, 400)
                  //   }
                  // }}
                  >
                    <div className="show-btn yellow lg green   ">
                      <p
                        className="show-time-flow"
                        style={{
                          marginTop:
                            !!show?.mf_name && show?.mf_name?.toLowerCase() !== '2d'
                              ? undefined
                              : '',
                        }}
                      >
                        {/* {show?.showTime} */}
                        {moment(show?.date_final_output).format('hh:mm A')}
                      </p>
                      {/* {show?.mf_name && show?.mf_name.toLowerCase() !== '2d' && (
                      <button className="three_d">
                        {show?.mf_name.toLowerCase() == '4dx' ? (
                          <Dx />
                        ) : (
                          show?.mf_name
                        )}
                      </button>
                    )} */}

                      {show && (
                        <button className="three_d">
                          <>
                            {show?.mf_name}
                          </>
                        </button>
                      )}
                    </div>

                    {false &&
                      show?.mf_name &&
                      show?.mf_name.toLowerCase() !== '2d' && (
                        <span>{show?.mf_name}</span>
                      )}
                    {show.statusType == 'Fully Booked' && (
                      <article className="movie-card__tooltip text-uppercase">
                        <p style={{ width: isMobile ? undefined : 'max-content' }}>
                          {t(show.statusType)}
                        </p>
                      </article>
                    )}
                  </div>
                </React.Fragment>
              ))}
          </article>
        </div>

        {!schedulesAfterMidnight || schedulesAfterMidnight?.length === 0 ? (
          <></>
        ) : (
          <h6
            className="booking_shows pl-2 align-items-center"
            style={{ borderLeft: '1px solid var(--dark-8)' }}
          >
            {t('After Midnight')}
          </h6>
        )}

        {!schedulesAfterMidnight || schedulesAfterMidnight?.length === 0 ? (
          <></>
        ) : (
          <article className="booking_shows">
            {schedulesAfterMidnight?.map((show, index) => (
              <React.Fragment key={index}>
                <div
                  className={`movie-card__tooltip__wrapper ${show.statusType == 'Fully Booked' ? 'inactive' : ''
                    } ${userSelection?.selectedScreen?.screen_id ===
                      screen?.screen_id &&
                      userSelection?.selectedMovieVersion?.movie_details_id ===
                      format?.movie_details_id &&
                      userSelection?.selectedShow?.ss_actual_start_date_time ===
                      show?.ss_actual_start_date_time
                      ? 'active__'
                      : ''
                    }
                       ${show?.mf_name && show?.mf_name.toLowerCase() !== '2d'
                      ? 'with_format'
                      : ''
                    }
                    `}
                  style={{
                    cursor: 'pointer',
                    height: '38px',
                    margin: isMobile ? '5px' : undefined,
                  }}
                  onClick={
                    // isTouch ? undefined :
                    () => {
                      if (show.statusType == 'Fully Booked') {
                        console.log('statusType', show.statusType);
                        return;
                      }

                      dispatch(resetScreeningTypes());
                      dispatch(
                        setOndemandShow({ show, screen, movieVersion: format }),
                      ).then(() => {
                        setTimeout(() => {
                          onClickShowMobile({ show, screen, movieVersion: format });
                        }, 0);
                      });
                      // if (isMobile) {
                      // }
                    }
                  }
                // onTouchStart={
                //   !isTouch ? undefined :
                //   () => {
                //   if (isMobile) {
                //     console.log('onTouchStart')
                //     dispatch(resetScreeningTypes())
                //     dispatch(setOndemandShow({show, screen, movieVersion: format}))
                //     setTimeout(() => {
                //       onClickShowMobile()
                //     }, 400)
                //   }
                // }}
                >
                  <div className="show-btn yellow lg green   ">
                    <p
                      className="show-time-flow"
                      style={{
                        marginTop:
                          !!show?.mf_name && show?.mf_name?.toLowerCase() !== '2d'
                            ? undefined
                            : 'unset',
                      }}
                    >
                      {show?.showTime}
                    </p>
                    {show?.mf_name && show?.mf_name.toLowerCase() !== '2d' && (
                      <button className="three_d">
                        {show?.mf_name.toLowerCase() == '4dx' ? (
                          <Dx />
                        ) : (
                          show?.mf_name
                        )}
                      </button>
                    )}
                  </div>

                  {false &&
                    show?.mf_name &&
                    show?.mf_name.toLowerCase() !== '2d' && (
                      <span>{show?.mf_name}</span>
                    )}
                  {show.statusType == 'Fully Booked' && (
                    <article className="movie-card__tooltip text-uppercase">
                      <p style={{ width: isMobile ? undefined : 'max-content' }}>
                        {t(show.statusType)}
                      </p>
                    </article>
                  )}
                </div>
              </React.Fragment>
            ))}
          </article>
        )}

        {schedules.length === 0 && (
          <>
            <div className="row">
              <div className="col-12 text-center">
                <p className="no-result">
                  {t('sorry-on-this-date-private-ondemand-not-available')}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {/*<div>
        <button>Golden</button>
      </div>*/}
    </>
  );
};

export default withNamespaces()(BookingShows);
