import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setStage } from '../../store/movies/movieActions';
import { motion } from 'framer-motion';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import useIsDesktop from '@src/customHooks/useIsDesktop';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { BASEURL } from '../../config';
import { checkIfURLSame, getLabelToshow } from '@helper/formatting';
import { getLangSpecificAttribute } from '@helper/languages';
import moment from 'moment';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import modalVideo from 'react-modal-video'

// Images
import poster from '@assets/pictures/sample-poster-new.png';
import { retry } from '@reduxjs/toolkit/dist/query';
const _ = require('lodash');

const MovieCardNew = (props) => {
  const {
    slider,
    onClick,
    classes,
    isUpcoming = false,
    now_demand_count = 0,
  } = props;
  const { pathname } = useLocation();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id } = selected_language;

  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const abc = () => { };
  const { iso_2 } = selected_language;
  const history = useHistory();
  const t = props.t;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDeskTop = useIsDesktop();

  const labelsToShow = getLabelToshow(props?.label_to_show);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

let available_movie_genre = props?.genres_with_translations?.map((y) => y?.g_name) || [];
// console.log('available_movie_genre:', available_movie_genre)

let available_movie_lang = props?.languages_with_translations
  ?.filter((t) => t?.md_is_active === "Y")
  ?.map((x) => x?.lang_name) || [];

let filtered_movie_lang = [...new Set(available_movie_lang)].join(', ');


  const calculateTopPosition = () =>
    isImageLoaded
      ? isTablet
        ? "calc(84% - 30px)"
        : isMobile
          ? "calc(80.5% - 30px)"
          : "calc(90.5% - 30px)"
      : "calc(84% - 30px)";


  const imageHeight = () => {
    if (isDeskTop) {
      return "364px";
    } else if (isTablet) {
      return "245px";
    } else {
      return "196px";
    }
  }


  const circleSize = !isMobile ? 30 : 20;
  const leftRight = isMobile ? -30 : -40;


  return (
    <>
      <div className='d-block parent_poster_wrapper'>
        <motion.article
          className={`${slider ? 'slider_spacing' : ''} ${classes}`}
          initial={{ x: -75, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.75 }}
          title={
            getLangSpecificAttribute(props?.altMovieContent, lang_id, 'mc_title') ||
            props?.movie_title ||
            props?.mc_title
          }
        >
          <div className={`${'movie_card_new'} `} onClick={onClick}>
            {/*has-tooltip previous class for on hover button*/}
            {/* Image */}

            <figure className='relative'>
              { isUpcoming && props?.mrrdr_release_date && (
                <div className="movie_card_new_label_wrapper">
                  <span className="movie_card_new_label_background" />
                  <span className="movie_card_new_label">
                    {/* {!isMobile && t('Releasing')}{' '} */}
                    {moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
                  </span>
                </div>
              )}
              <img
                src={
                  getLangSpecificAttribute(
                    props?.languageSpecificImages || props?.altMovieContent,
                    lang_id,
                    'artwork',
                  ) || dummyPoster
                }
                className="img-fluid"
                alt={t('alt.Movie Poster')}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyPosterStatic;
                }}
                onLoad={() => setIsImageLoaded(true)}
                style={{ height: imageHeight() }}
              />

              <div className='details'>
                <button className='book_now_btn'>
                  {!isUpcoming ? 'Buy Now' : 'More'}
                </button>
                <div className='rating-tag text-white rounded' style={{ position: 'absolute', top: '34px', right: '15px', background: '#259486', height: '22px', width: '50px', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {props?.rating || 'PG13'}
                </div>
                <div
                  style={{
                    border: "2px dashed gray",
                    position: "absolute",
                    top: calculateTopPosition(),
                    right: "0",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "0 20px",
                    display: isImageLoaded ? "block" : "none"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: `${leftRight}px`,
                        transform: "translateY(-50%)",
                        width: `${circleSize}px`,
                        height:`${circleSize}px`,
                        borderRadius: "50%",
                        backgroundColor: "#231F20",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: `${leftRight}px`,
                        transform: "translateY(-50%)",
                        width: `${circleSize}px`,
                        height:`${circleSize}px`,
                        borderRadius: "50%",
                        backgroundColor: "#231F20",
                      }}
                    ></div>

                  </div>

                </div>
              </div>
            </figure>

            {/*Tool tip on hover */}
            {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

            {/* Label */}
            {!isUpcoming && labelsToShow?.length > 0 && (
              <div className="movie_card_new_label_wrapper" style={{height:'25px'}}>
                <span className="movie_card_new_label_background" />
                <span className="movie_card_new_label">
                  {getLangSpecificAttribute(
                    labelsToShow,
                    lang_id,
                    'label_name',
                    'lang_id',
                  ) ||
                    labelsToShow[0]?.label ||
                    ''}
                </span>
              </div>
            )}
            <div
              className={`movie_poster_details ${isUpcoming ? 'custom_min_height_adv' : ''}`}
              style={{
                height: isMobile && props?.movie_title?.length > 20 ? '80px' : ((!isDeskTop) ? '80px' : "70px"), position: 'relative'
              }}
            >
              <div className={`d-flex justify-content-center pb-2 ${isMobile ? '' : ''}`}
                style={{ gap: !isMobile ? '5px' : '6px', marginTop: (isMobile || isTablet) ? '20px' : '8px', fontWeight: 'normal' }}
              >
                <div className='responsive_font_movie_title'
                  style={{ textAlign: 'center' }}
                >
                  {getLangSpecificAttribute(
                    props?.altMovieContent,
                    lang_id,
                    'mc_title',
                  ) ||
                    props?.movie_title || props?.mc_title
                  }
                  <br />
                  {filtered_movie_lang?.length > 0 && (
                    <div className='time_poster text-center text-white'>
                     <span> {filtered_movie_lang } | {available_movie_genre.join(',')} </span>
                     <br/>
                     {/* <span> {Math.floor(props?.mrrdr_runtime / 60)} hrs {props?.mrrdr_runtime % 60 !=0
                      ? (props?.mrrdr_runtime % 60) + 'mins' : ''
                      }</span> */}
                    </div>
                    
                  )}
                </div>

              </div>

            </div>
          </div>
          {isUpcoming && now_demand_count > 0 && false && (
            <div className="parent_up_btn">
              <button className="up_btn" onClick={onClick}>
                {t('Create private screening')}
              </button>
            </div>
          )}
        </motion.article>
      </div>
    </>
  );
};

// Default Props
MovieCardNew.defaultProps = {
  img: poster,
  slider: false,
  classes: '',
  onClick: () => { },
};

export default withNamespaces()(MovieCardNew);
